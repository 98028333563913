export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"robots","content":"none"},{"hid":"description","name":"description","content":""},{"hid":"og:site_name","property":"og:site_name","content":""},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:url","property":"og:url","content":"https://event.hikky.dev"},{"hid":"og:title","property":"og:title","content":""},{"hid":"og:description","property":"og:description","content":""},{"hid":"og:image","property":"og:image","content":"https://event.hikky.dev/2024Winter/images/ogp.jpg"},{"hid":"twitter:card","name":"twitter:card","content":"summary_large_image"},{"hid":"twitter:site","name":"twitter:site","content":""},{"hid":"twitter:creator","name":"twitter:creator","content":""},{"hid":"note:card","name":"note:card","content":"summary_large_image"}],"link":[{"hid":"icon","rel":"icon","type":"image/x-icon","href":"/2024Winter/favicon.ico"}],"style":[],"script":[],"noscript":[{"children":"Javascript is required"}]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false