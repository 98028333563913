import dayjs, { UnitType, ManipulateType } from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
// eslint-disable-next-line import/no-named-as-default-member
const { extend } = dayjs

extend(localizedFormat)
extend(timezone)
extend(utc)
extend(isBetween)
extend(isSameOrBefore)
extend(isSameOrAfter)

dayjs.tz.setDefault('Asia/Tokyo')

type Locale = 'ja' | 'en'

// イベント開催期間(JST)
export const eventStartDateTime = dayjs.tz('2024-12-07T10:00:00').toDate()
export const eventEndDateTime = dayjs.tz('2024-12-22T23:00:00').toDate()

// 出展申込み期限(JST)
export const entryStartDateTime = dayjs.tz('2024-09-06T15:00:00').toDate()
export const entryDeadlineDateTime = dayjs.tz('2024-09-16T23:59:59').toDate()
// 1次抽選終了(JST)
export const firstLotteryEndDateTime = dayjs.tz('2023-09-11T12:00:00').toDate()
// 1次支払い期限(JST)
export const firstPaidDeadlineDateTime = dayjs
  .tz('2023-09-17T23:59:59')
  .toDate()
// 2次抽選終了(JST)
export const secondLotteryEndDateTime = dayjs.tz('2023-09-25T16:00:00').toDate()
// 2次支払い期限(JST)
export const secondPaidDeadlineDateTime = dayjs
  .tz('2023-10-01T23:59:59')
  .toDate()

// 入稿物をプレビューワールドへ反映開始
export const applyWorldStartDateTime = dayjs.tz('2023-10-27T00:00').toDate()

// 入稿受付期間(JST)
export const submitStartDateTime = dayjs.tz('2023-10-20T12:00:00').toDate()
export const submitEndDateTime = dayjs.tz('2023-11-19T23:59:59').toDate()
export const submitEndDateTimeEmergency = dayjs
  .tz('2023-11-20T00:15:59')
  .toDate()

// VketCloud入稿受付期間(JST)
export const submitVketCloudStartDateTime = dayjs
  .tz('2023-05-26T12:00:00')
  .toDate()
export const submitVketCloudEndDateTime = dayjs
  .tz('2023-07-07T23:59:59')
  .toDate()

// VREC開催期間(JST) TODO: re-setting
export const vrEcStartDateTime = dayjs.tz('2023-07-26T15:45:00').toDate()

/**
 * @description 日付取得・フォーマット
 * @returns Result:Date
 */
export function formatDate(
  format: string,
  date: Date | string = new Date()
): string {
  return dayjs(date).format(format)
}

export function formatEnglishDate(date: Date | string): string {
  return formatDate('LL', date)
}

export function formatJapaneseDate(date: Date | string): string {
  return formatDate('YYYY/MM/DD', date)
}

export function formatEnglishDateTime(date: Date | string): string {
  return formatDate('LL LT', date)
}

export function formatJapaneseDateTime(date: Date | string): string {
  return formatDate('YYYY/MM/DD h:mm A', date)
}

export function formatDateUnixTime(date: Date | string = new Date()): number {
  return dayjs(date).unix()
}

export function convertIsoDateStringToDateObject(isoDate: string): Date {
  return dayjs(isoDate).toDate()
}

export function convertIsoDateStringToLocalDateTime(
  isoDate: string,
  locale: string
): string {
  const date = convertIsoDateStringToDateObject(isoDate)
  return locale === 'ja'
    ? formatJapaneseDateTime(date)
    : formatEnglishDateTime(date)
}

/**
 * タイムゾーンをJSTからローカルのタイムゾーンに変更
 */
export function formatJSTtoLocalTimezone(date: string): string {
  const dateJST = dayjs(date).tz('Asia/Tokyo', true)
  const dateUSJ = dayjs(dateJST).tz('UTC').format('YYYY-MM-DDTHH:mm')
  return dayjs(dateUSJ).tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm')
}

/**
 * タイムゾーンをローカルのタイムゾーンからJSTに変更
 */
export function formatLocalTimezoneToJST(date: string): string {
  const dateWithTimezone = dayjs(date).tz(dayjs.tz.guess(), true)
  return dayjs(dateWithTimezone).tz('Asia/Tokyo').format('YYYY-MM-DDTHH:mm')
}

export function getCurrentDate(locale: Locale = 'ja'): string {
  const date = new Date()
  return locale === 'ja' ? formatJapaneseDate(date) : formatEnglishDate(date)
}

/**
 * ユーザのローカルのタイムゾーンを取得
 */
export function getLocalTimezone(): string {
  return 'UTC ' + dayjs().tz(dayjs.tz.guess()).format('Z')
}

/**
 * 日付加算されたDateオブジェクトの作成
 */
export function addDateTime(
  value: number,
  unit: ManipulateType = 'day',
  base: Date | string
): Date {
  return dayjs(base).add(value, unit).toDate()
}

/**
 * @description 時差対応
 * @returns Result:Date
 */
export function convertTimeToUtc(date: Date | string): string {
  return dayjs(date).utc().format()
}

export function convertUTCToJST(date: Date): Date {
  return dayjs(date).tz().toDate()
}

/**
 * @description 指定日時の比較判定
 * @returns Result:number
 */
export function diffDays(
  to: Date | string,
  from: Date | string | undefined = undefined,
  format: UnitType = 'day'
): number {
  const toDate = dayjs(to)
  const fromDate = from ? dayjs(from) : dayjs()
  return fromDate.diff(toDate, format)
}

export function getDiffTimeByUnit(
  to: Date | string,
  from: Date | string | undefined = undefined,
  unitType: UnitType = 'day'
): number {
  const millisecond = diffDays(to, from, 'millisecond')
  if (unitType === 'day') {
    return Math.floor(millisecond / 1000 / 60 / 60 / 24)
  }
  if (unitType === 'hour') {
    return Math.floor(millisecond / 1000 / 60 / 60) % 24
  }
  if (unitType === 'minute') {
    return Math.floor(millisecond / 1000 / 60) % 60
  }
  if (unitType === 'second') {
    return Math.floor(millisecond / 1000) % 60
  }
  return -1
}

export function startOfTargetDate(date: Date | string): string {
  return dayjs(date).startOf('day').toString()
}

export function endOfTargetDate(date: Date | string): string {
  return dayjs(date).endOf('day').toString()
}

export function isSameOrBeforeTargetDate(date: Date | string): boolean {
  return dayjs().isSameOrBefore(dayjs(date))
}

export function isBeforeTargetDate(date: Date | string): boolean {
  return dayjs().isBefore(dayjs(date))
}

export function isBetweenTargetDates(
  fromDate: Date | string,
  toDate: Date | string
): boolean {
  return dayjs().isBetween(dayjs(fromDate), dayjs(toDate))
}

export function isSameOrAfterTargetDate(date: Date | string): boolean {
  return dayjs().isSameOrAfter(dayjs(date))
}

export function isAfterTargetDate(date: Date | string): boolean {
  return dayjs().isAfter(dayjs(date))
}

/**
 * @description イベント開始日と終了日に関する判定
 * @returns Result:Boolean
 */
export function isBeforeStartEvent(): boolean {
  return isSameOrBeforeTargetDate(eventStartDateTime)
}

export function isStartEvent(): boolean {
  return isSameOrAfterTargetDate(eventStartDateTime)
}

export function isBetweenEvent(): boolean {
  return isBetweenTargetDates(eventStartDateTime, eventEndDateTime)
}

export function isAfterEndEvent(): boolean {
  return isSameOrAfterTargetDate(eventEndDateTime)
}

/**
 * @description 出展申込み期限より前か？
 * @returns Result:Boolean
 */
export function isBeforePcEntryDeadline(): boolean {
  return isSameOrBeforeTargetDate(entryDeadlineDateTime)
}

/**
 * @description 1次抽選終了より前か？
 * @returns Result:Boolean
 */
export function isBeforePcFirstLotteryEndDateTime(): boolean {
  return isSameOrBeforeTargetDate(firstLotteryEndDateTime)
}

/**
 * @description 1次支払い期限より前か？
 * @returns Result:Boolean
 */
export function isBeforePcFirstPaidDeadlineDateTime(): boolean {
  return isSameOrBeforeTargetDate(firstPaidDeadlineDateTime)
}

/**
 * @description 2次抽選終了より前か？
 * @returns Result:Boolean
 */
export function isBeforePcSecondLotteryEndDateTime(): boolean {
  return isSameOrBeforeTargetDate(secondLotteryEndDateTime)
}

/**
 * @description 2次支払い期限より前か？
 * @returns Result:Boolean
 */
export function isBeforePcSecondPaidDeadlineDateTime(): boolean {
  return isSameOrBeforeTargetDate(secondPaidDeadlineDateTime)
}

/**
 * @description 入稿開始日と終了日に関する判定
 * @returns Result:Boolean
 */
export function isBeforeStartSubmit(): boolean {
  return isSameOrBeforeTargetDate(submitStartDateTime)
}

export function isStartSubmit(): boolean {
  return isSameOrAfterTargetDate(submitStartDateTime)
}

export function isStartVketCloudSubmit(): boolean {
  return isSameOrAfterTargetDate(submitVketCloudStartDateTime)
}

export function isBeforeEndSubmit(): boolean {
  return isSameOrBeforeTargetDate(submitEndDateTime)
}

export function isBetweenSubmit(): boolean {
  return isBetweenTargetDates(submitStartDateTime, submitEndDateTime)
}

export function isBetweenSubmitVketCloud(): boolean {
  return isBetweenTargetDates(
    submitVketCloudStartDateTime,
    submitVketCloudEndDateTime
  )
}

export function isAfterEndSubmit(): boolean {
  return isSameOrAfterTargetDate(submitEndDateTime)
}

export function isBeforeStartSubmitVketCloud(): boolean {
  return isSameOrBeforeTargetDate(submitVketCloudStartDateTime)
}

export function isBeforeEndSubmitVketCloud(): boolean {
  return isSameOrBeforeTargetDate(submitVketCloudEndDateTime)
}

/**
 * @description 抽選期間に関する判定
 * @returns Result:Boolean
 */
export function isBetweenLottery(): boolean {
  return isBetweenTargetDates(entryDeadlineDateTime, firstLotteryEndDateTime)
}

export function isBeforeLotteryEnd(): boolean {
  return isSameOrBeforeTargetDate(entryDeadlineDateTime)
}

/**
 * @description 開催までの残り時間(ミリ秒)
 * @returns Result:number
 */
export function getStartLimit(unitType: UnitType = 'day'): number {
  return getDiffTimeByUnit(new Date(), eventStartDateTime, unitType)
}

/**
 * @description 申込の残り時間(ミリ秒)
 * @returns Result:number
 */
export function getEntryLimit(unitType: UnitType = 'day'): number {
  return getDiffTimeByUnit(new Date(), entryDeadlineDateTime, unitType)
}

// UCイベント表示開始日
// 日時は仮
export const ucEventStartDateTime = dayjs.tz('2023-11-20T23:59:59').toDate()
export function isBeforeStartUcEvent(): boolean {
  return isSameOrBeforeTargetDate(ucEventStartDateTime)
}
export function isStartUcEvent(): boolean {
  return isSameOrAfterTargetDate(ucEventStartDateTime)
}

// カタログ表示開始日
export const catalogStartDateTime = dayjs.tz('2024-11-30T12:00:00').toDate()
export function isBeforeStartCatalog(): boolean {
  return isSameOrBeforeTargetDate(catalogStartDateTime)
}
export function isStartCatalog(): boolean {
  return isSameOrAfterTargetDate(catalogStartDateTime)
}

/**
 * @description VREC開始日と終了日に関する判定
 * @returns Result:Boolean
 */
export function isBeforeStartVrEc(): boolean {
  return isSameOrBeforeTargetDate(vrEcStartDateTime)
}

export function isStartVrEc(): boolean {
  return isSameOrAfterTargetDate(vrEcStartDateTime)
}
