import { defineNuxtPlugin } from 'nuxt/app'

export default defineNuxtPlugin(async ({ $config }) => {
  if ($config.NUXT_ENV_API_PREFIX === '/mock') {
    if (process.server) {
      const server = (await import('@/mocks/server')).server
      await server.listen({
        onUnhandledRequest: 'bypass',
      })
    } else {
      const worker = (await import('@/mocks/browser')).worker
      await worker.start({
        // モックAPIサーバーで定義されたエンドポイント以外にアクセスした場合に、警告しない
        // https://mswjs.io/docs/api/setup-worker/start#onunhandledrequest
        onUnhandledRequest: 'bypass',
      })
    }
  }
})
