/**
 * https://github.com/nuxt-community/markdownit-module/issues/47#issuecomment-1345268133
 * @packageDocumentation
 */

import md from 'markdown-it'
import { defineNuxtPlugin } from 'nuxt/app'

export default defineNuxtPlugin(() => {
  const renderer = md()
  return {
    provide: {
      mdRenderer: renderer,
    },
  }
})
