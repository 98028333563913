<!--
- [x] vue2系(Options API)の書き方まま
- [x] Composition APIで書けている
- [x] Nuxt.jsに依存していない
- [ ] unplugin-auto-import を導入する前提の書き方ができている
- [ ] ロジック観点でのリファクタリング(FS主管)が完了している
- [ ] デザイン観点でのリファクタリング(DD主管)が完了している
- [ ] 適切にコメントが記載されている
- [x] Unit Testを通過している
- [ ] storiesが適切に記載されている
-->
<template>
  <button
    class="ha-base-button"
    :disabled="disabled"
    :type="type"
    @click="onClick"
  >
    <slot>button label</slot>
  </button>
</template>
<script setup lang="ts">
export type ButtonType = 'button' | 'reset' | 'submit'

const props = withDefaults(
  defineProps<{
    disabled?: boolean
    type?: ButtonType
  }>(),
  {
    disabled: false,
    type: 'button',
  }
)
const emit = defineEmits<{
  (e: 'click', _e: MouseEvent): void
}>()
const onClick = (event: MouseEvent): void => {
  if (props.disabled) return
  emit('click', event)
}
</script>
<style lang="scss" scoped>
.ha-base-button {
  max-height: 100%;
  max-width: 100%;
}
</style>
