<template>
  <div class="app">
    <Head>
      <template v-if="currentLang === 'ja'">
        <Link rel="alternate" hreflang="en" :href="currentEnFullPath" />
      </template>
      <template v-if="currentLang === 'en'">
        <Link rel="canonical" :href="currentJaFullPath" />
      </template>
    </Head>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
<script lang="ts" setup>
const i18n = useI18n()
const route = useRoute()

const siteName = String(i18n.t('meta.siteName') ?? '')

const currentFullPath = ref(`${useRuntimeConfig().public.url}${route.fullPath}`)
const currentLang = ref(i18n.locale.value)
const currentJaFullPath = computed(() => {
  if (currentLang.value === 'ja') {
    return currentFullPath.value
  } else {
    return currentFullPath.value
      .replace(/\/en(\/|$)/, '/')
      .replace(/\/{2,}/, '/')
  }
})
const currentEnFullPath = computed(() => {
  if (currentLang.value === 'en') {
    return currentFullPath.value
  } else {
    const path = route.fullPath.endsWith('/')
      ? route.fullPath
      : `${route.fullPath}/`
    return `${useRuntimeConfig().public.url}/en${path}`
  }
})

// fullPathとlangをwatchして、fullPathを更新する
watch(
  () => [route.fullPath, i18n.locale],
  () => {
    currentFullPath.value = `${useRuntimeConfig().public.url}${route.fullPath}`
    currentLang.value = i18n.locale.value
  }
)

useHead(() => ({
  titleTemplate: `%s | ${siteName}`,
  htmlAttrs: {
    lang: currentLang.value,
  },
}))
</script>
