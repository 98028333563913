<!--
- [x] vue2系(Options API)の書き方まま
- [x] Composition APIで書けている
- [x] Nuxt.jsに依存していない
- [x] unplugin-auto-import を導入する前提の書き方ができている
- [x] ロジック観点でのリファクタリング(FS主管)が完了している
- [ ] デザイン観点でのリファクタリング(DD主管)が完了している
- [ ] 適切にコメントが記載されている
- [x] Unit Testを通過している
- [ ] storiesが適切に記載されている
-->
<template>
  <component
    :is="component"
    class="ha-link"
    v-bind="{ [isNuxtLink ? 'to' : 'href']: linkTo }"
    :target="blank ? '_blank' : undefined"
    :rel="rel"
  >
    <slot></slot>
  </component>
</template>

<script setup lang="ts">
import { resolveComponent } from 'vue'
import type { LocationQuery } from 'vue-router'
const props = withDefaults(
  defineProps<{
    to?: string
    blank?: boolean
    rel?: HTMLAnchorElement['rel']
    forceAnchorLink?: boolean
    noLocale?: boolean
    // toにqueryパラメータを入れてしまうと、localePathで消えるため、外部リンク以外はこちらのパラメータを使用してリンクを生成
    query?: LocationQuery
  }>(),
  {
    to: '',
    blank: false,
    rel: undefined,
    forceAnchorLink: false,
    noLocale: false,
    query: undefined,
  }
)
const isExternalReference = computed(() => !!props.to?.match(/^https?:\/\//))
const isNuxtLink = computed(() => {
  // vketイベントサイトへのURLは通常の画面遷移
  if (isVketLink.value) return false
  return !props.forceAnchorLink && !isExternalReference.value
})
// vketイベントサイトへのリンクか？
const isVketLink = computed(() => {
  // topページは開催中のvket
  if (props.to === '/' || props.to === '/en') return true
  const path = props.to.replace('/en', '') // たまにlocalePath入れてる箇所があるので抜いておく
  return /^\/\d{4}[a-zA-Z]+\/?.*/.test(path)
})
const component = computed(() =>
  isNuxtLink.value ? resolveComponent('nuxt-link') : 'a'
)
const linkTo = computed(() => {
  // vketへのリンクは’en/’等をつけたいのでNuxt-linkでなくてもlocalePathする
  if (isVketLink.value) {
    const localePath = useLocalePath()
    return localePath({ path: props.to, query: props.query })
  }
  if (!isNuxtLink.value) {
    return props.to
  }

  const localePath = useLocalePath()
  return localePath({ path: props.to, query: props.query })
})
</script>
