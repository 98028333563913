<i18n lang="yaml">
ja:
  message: |-
    誠に申し訳ございません。
    システムエラーが発生しました。
  label: トップページに戻る
en:
  message: |-
    We are sorry.
    An error has occurred.
  label: Back to top page
</i18n>
<template>
  <div class="app">
    <NuxtLayout class="-error-page">
      <div class="error">
        <div class="error-box">
          <span class="error-title">ERROR MESSAGE</span>
          <template v-if="error">
            <p class="error-text">{{ error.message }}</p>
          </template>
          <template v-else>
            <p class="error-text">Something went wrong...</p>
          </template>
        </div>
        <HaImage
          src="/2024Summer/images/error_vket-chan.png"
          :width="300"
          alt="Error Vket-chan"
          class="error-image"
        />
        <div class="message-box">
          <p class="text">{{ i18n.t('message') }}</p>
          <HaLink to="/" class="link">
            <HmButton class="button">{{ i18n.t('label') }}</HmButton>
          </HaLink>
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>
<script lang="ts" setup>
import { onMounted } from 'vue'
import { removeCookieValue } from '@/utils/storage-control'

const { value: error } = useError()
const runtimeConfig = useRuntimeConfig()
const currentEventPath = useRuntimeConfig().public.currentEventPath
const i18n = useI18n()
const route = useRoute()

useHead(() => ({
  title: 'ERROR',
  meta: [
    {
      hid: 'og:title',
      property: 'og:title',
      content: 'ERRPR',
    },
    {
      hid: 'description',
      name: 'description',
      content: `${i18n.t('meta.description')}`,
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: `${i18n.t('meta.description')}`,
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: `${i18n.t('meta.title.top')}`,
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: `${runtimeConfig.public.url}${route.fullPath}`,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: `${runtimeConfig.public.url}/2024Winter/images/ogp${
        i18n.locale.value === 'en' ? '_en' : ''
      }.jpg`,
    },
  ],
}))

// VSO認証のcookieがある場合は滅殺する
// ref: /composables/useAuthSso.ts
onMounted(() => {
  const COOKIE_KEY_EXP = 'sso-token-expires-in'
  const COOKIE_KEY_IAT = 'sso-token-created-at'
  const COOKIE_KEY_JWT = 'sso-token-jwt'
  removeCookieValue(COOKIE_KEY_EXP)
  removeCookieValue(COOKIE_KEY_IAT)
  removeCookieValue(COOKIE_KEY_JWT)
})
</script>
<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.error {
  height: -webkit-fill-available;
  min-height: 51.5vh;
  padding: v.$header-height-pc 0 56px;
  z-index: v.$zindex-main;
  @include m.sp() {
    padding: v.$header-height-sp 10px;
  }

  &-box {
    background-color: v.$white;
    border: solid 2px v.$primary-color;
    border-radius: 12px;
    margin: 0 auto 20px;
    max-width: 100%;
    min-width: 200px;
    padding: 18px 8px 16px;
    position: relative;
    text-align: center;
    width: fit-content;

    &::after {
      background-color: v.$white;
      border-right: 2px solid v.$primary-color;
      border-top: 2px solid v.$primary-color;
      bottom: -9px;
      content: '';
      height: 15px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%) rotate(135deg);
      width: 15px;
    }
  }

  &-title {
    background-color: v.$primary-color;
    border-radius: 2px;
    color: v.$white;
    display: inline-block;
    font-weight: 700;
    left: 12px;
    padding: 4px;
    position: absolute;
    top: -15px;
  }

  &-text {
    line-height: 150%;
    overflow-wrap: break-word;
  }

  &-image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    width: 300px;
  }
}

.message-box {
  margin: 40px auto;
  max-width: 100%;
  text-align: center;
  width: 300px;

  > .text {
    color: v.$primary-color;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    margin: 0 0 16px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }

  > .link {
    display: inline-block;
    margin: 0 auto;
    max-width: 100%;
    width: 300px;
  }
}
</style>
