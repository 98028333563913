import revive_payload_client_4sVQNw7RlN from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/circleci/project/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/circleci/project/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/circleci/project/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/circleci/project/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/home/circleci/project/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import fetch_CZvRlf0ZJN from "/home/circleci/project/src/plugins/fetch.ts";
import gtm_client_Hp0PRXEnoA from "/home/circleci/project/src/plugins/gtm.client.ts";
import markdownit_Pxlf4EgQWe from "/home/circleci/project/src/plugins/markdownit.ts";
import mockServer_xBAIhFJgum from "/home/circleci/project/src/plugins/mockServer.ts";
import runtimeConfig_K4NzNKWGU0 from "/home/circleci/project/src/plugins/runtimeConfig.ts";
import toast_client_5GxzM6P4QL from "/home/circleci/project/src/plugins/toast.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  fetch_CZvRlf0ZJN,
  gtm_client_Hp0PRXEnoA,
  markdownit_Pxlf4EgQWe,
  mockServer_xBAIhFJgum,
  runtimeConfig_K4NzNKWGU0,
  toast_client_5GxzM6P4QL
]