<!--
- [x] vue2系(Options API)の書き方まま
- [x] Composition APIで書けている
- [x] Nuxt.jsに依存していない
- [x] unplugin-auto-import を導入する前提の書き方ができている
- [x] ロジック観点でのリファクタリング(FS主管)が完了している
- [ ] デザイン観点でのリファクタリング(DD主管)が完了している
- [x] 適切にコメントが記載されている
- [x] Unit Testを通過している
- [ ] storiesが適切に記載されている
onerror="this.onerror=null; this.src='/images/no-image_1x1.jpg';"
-->
<template>
  <img
    class="ha-image"
    :loading="isLazy ? 'lazy' : undefined"
    :src="imageUrl"
    :alt="alt"
    :width="width"
    :height="height"
    @error="onError"
  />
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { constant } from '@/utils/constant'

const props = withDefaults(
  defineProps<{
    /**
     * src 属性に渡されるもの。
     * 無指定で '/images/no-image.png'、
     * エラー時に「無」の画像に置き換えられる。
     */
    src?: string | null
    alt?: string
    width?: number
    height?: number
    /** デフォルト有効(loading="lazy") :is-lazy="false" で無効 */
    isLazy?: boolean
    /** エラー時の置き換え「無」画像のurl */
    noImage?: string
  }>(),
  {
    src: '',
    alt: '',
    width: undefined,
    height: undefined,
    isLazy: true,
    noImage: '',
  }
)
const hasError = ref(false)
const onError = () => {
  hasError.value = true
}

const imageUrl = computed(() => {
  if (hasError.value) {
    return props.noImage || constant.images.none
  }
  return props.src || constant.images.none
})
</script>
