/**
 * runtimeConfig・appConfigに置けない定数を置く場所。
 */
export const constant = {
  images: {
    none: '/images/no-image.png',
    none1x1: '/images/no-image_1x1.jpg',
    noneCustom: '/images/no-image-custom.png',
  },
} as const
