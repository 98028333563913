import { default as warmupeTkPSILLrnMeta } from "/home/circleci/project/src/pages/_ah/warmup.vue?macro=true";
import { default as _91_46_46_46slug_93wqSrdSrTCXMeta } from "/home/circleci/project/src/pages/[...slug].vue?macro=true";
import { default as aboutU5bqUFmwfOMeta } from "/home/circleci/project/src/pages/about.vue?macro=true";
import { default as accessp6D2cZBY4cMeta } from "/home/circleci/project/src/pages/access.vue?macro=true";
import { default as alive9D4FVmn0JpMeta } from "/home/circleci/project/src/pages/alive.vue?macro=true";
import { default as mediaGYAVVL4EGhMeta } from "/home/circleci/project/src/pages/media.vue?macro=true";
export default [
  {
    name: warmupeTkPSILLrnMeta?.name ?? "_ah-warmup___ja",
    path: warmupeTkPSILLrnMeta?.path ?? "/_ah/warmup",
    meta: warmupeTkPSILLrnMeta || {},
    alias: warmupeTkPSILLrnMeta?.alias || [],
    redirect: warmupeTkPSILLrnMeta?.redirect,
    component: () => import("/home/circleci/project/src/pages/_ah/warmup.vue").then(m => m.default || m)
  },
  {
    name: warmupeTkPSILLrnMeta?.name ?? "_ah-warmup___en",
    path: warmupeTkPSILLrnMeta?.path ?? "/en/_ah/warmup",
    meta: warmupeTkPSILLrnMeta || {},
    alias: warmupeTkPSILLrnMeta?.alias || [],
    redirect: warmupeTkPSILLrnMeta?.redirect,
    component: () => import("/home/circleci/project/src/pages/_ah/warmup.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93wqSrdSrTCXMeta?.name ?? "slug___ja",
    path: _91_46_46_46slug_93wqSrdSrTCXMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93wqSrdSrTCXMeta || {},
    alias: _91_46_46_46slug_93wqSrdSrTCXMeta?.alias || [],
    redirect: _91_46_46_46slug_93wqSrdSrTCXMeta?.redirect,
    component: () => import("/home/circleci/project/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93wqSrdSrTCXMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_93wqSrdSrTCXMeta?.path ?? "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93wqSrdSrTCXMeta || {},
    alias: _91_46_46_46slug_93wqSrdSrTCXMeta?.alias || [],
    redirect: _91_46_46_46slug_93wqSrdSrTCXMeta?.redirect,
    component: () => import("/home/circleci/project/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: aboutU5bqUFmwfOMeta?.name ?? "about___ja",
    path: aboutU5bqUFmwfOMeta?.path ?? "/about",
    meta: aboutU5bqUFmwfOMeta || {},
    alias: aboutU5bqUFmwfOMeta?.alias || [],
    redirect: aboutU5bqUFmwfOMeta?.redirect,
    component: () => import("/home/circleci/project/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutU5bqUFmwfOMeta?.name ?? "about___en",
    path: aboutU5bqUFmwfOMeta?.path ?? "/en/about",
    meta: aboutU5bqUFmwfOMeta || {},
    alias: aboutU5bqUFmwfOMeta?.alias || [],
    redirect: aboutU5bqUFmwfOMeta?.redirect,
    component: () => import("/home/circleci/project/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: accessp6D2cZBY4cMeta?.name ?? "access___ja",
    path: accessp6D2cZBY4cMeta?.path ?? "/access",
    meta: accessp6D2cZBY4cMeta || {},
    alias: accessp6D2cZBY4cMeta?.alias || [],
    redirect: accessp6D2cZBY4cMeta?.redirect,
    component: () => import("/home/circleci/project/src/pages/access.vue").then(m => m.default || m)
  },
  {
    name: accessp6D2cZBY4cMeta?.name ?? "access___en",
    path: accessp6D2cZBY4cMeta?.path ?? "/en/access",
    meta: accessp6D2cZBY4cMeta || {},
    alias: accessp6D2cZBY4cMeta?.alias || [],
    redirect: accessp6D2cZBY4cMeta?.redirect,
    component: () => import("/home/circleci/project/src/pages/access.vue").then(m => m.default || m)
  },
  {
    name: alive9D4FVmn0JpMeta?.name ?? "alive___ja",
    path: alive9D4FVmn0JpMeta?.path ?? "/alive",
    meta: alive9D4FVmn0JpMeta || {},
    alias: alive9D4FVmn0JpMeta?.alias || [],
    redirect: alive9D4FVmn0JpMeta?.redirect,
    component: () => import("/home/circleci/project/src/pages/alive.vue").then(m => m.default || m)
  },
  {
    name: alive9D4FVmn0JpMeta?.name ?? "alive___en",
    path: alive9D4FVmn0JpMeta?.path ?? "/en/alive",
    meta: alive9D4FVmn0JpMeta || {},
    alias: alive9D4FVmn0JpMeta?.alias || [],
    redirect: alive9D4FVmn0JpMeta?.redirect,
    component: () => import("/home/circleci/project/src/pages/alive.vue").then(m => m.default || m)
  },
  {
    name: mediaGYAVVL4EGhMeta?.name ?? "media___ja",
    path: mediaGYAVVL4EGhMeta?.path ?? "/media",
    meta: mediaGYAVVL4EGhMeta || {},
    alias: mediaGYAVVL4EGhMeta?.alias || [],
    redirect: mediaGYAVVL4EGhMeta?.redirect,
    component: () => import("/home/circleci/project/src/pages/media.vue").then(m => m.default || m)
  },
  {
    name: mediaGYAVVL4EGhMeta?.name ?? "media___en",
    path: mediaGYAVVL4EGhMeta?.path ?? "/en/media",
    meta: mediaGYAVVL4EGhMeta || {},
    alias: mediaGYAVVL4EGhMeta?.alias || [],
    redirect: mediaGYAVVL4EGhMeta?.redirect,
    component: () => import("/home/circleci/project/src/pages/media.vue").then(m => m.default || m)
  }
]