// ref: https://v3.nuxtjs.org/guide/directory-structure/app.config
// note: Do not put any secret values inside app.config file. It is exposed to the user client bundle.
// todo: config/appConfig.ts に統合してこっちは消す
export default defineAppConfig({
  isEventRootApp: false, // イベントの基底サイトであるか、各イベントのサイトであるか
  currentEventPath: '2024Winter', // 現在行われているイベントのパス
  localStorageKeyShopifyId: 'v23s-shopify-id',
  localStorageKeyShopifySub: 'v23s-shopify-sub',
  localStorageKeyShopifyEmail: 'v23s-shopify-email',
  localStorageKeyShopifyName: 'v23s-shopify-name',
})
