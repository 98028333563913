import Vue3Toastify, { toast, UpdateOptions } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import { defineNuxtPlugin } from 'nuxt/app'

export default defineNuxtPlugin((nuxtApp) => {
  const options: UpdateOptions = {
    position: toast.POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
  }
  nuxtApp.vueApp.use(Vue3Toastify as any, options)

  return {
    provide: { toast },
  }
})
